import { ContactsFilter } from './subcomponents/ContactsFilter';
import { ContactsList } from './subcomponents/ContactsList';
import MapContainer from './subcomponents/MapContainer';
import React from 'react';
import { isNullUndefinedEmpty } from '../../helpers/helpers';
import userStateManager from '../../helpers/stateManager';

export default class ContactsLocations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterRegion: 'Perth',
            filterRegionTitle: 'Perth',
            filterFeaturedOnly: false,
            includeNearMe: true, //Featured contacts only if false
            openPopupId: '1',
            availableRegions: ['loading regions...'],
            contacts: [],
            selectedMarkerId: '', //Id only - so we can trigger the popup from the list view without a marker reference (this react plugin sucks...)
            isLoading: true,
        };
    }

    fetchRegions = function () {
        jQuery.ajax({
            type: 'GET',
            url: '/dfes-api/ContactsAPI/GetRegions',
            success: function (response, status) {
                this.setState({ availableRegions: response.data });
            }.bind(this),
            dataType: 'json',
        });
    };

    getDefaultRegion() {
        userStateManager.getUserProfileData((data) => {
            //Use the region from the user's first BGU, or fall back to the first available region
            var defaultFilterRegion =
                !isNullUndefinedEmpty(data.ProfileInfo) && !isNullUndefinedEmpty(data.ProfileInfo.BGUs)
                    ? data.ProfileInfo.BGUs[0]['Region']
                    : this.state.availableRegions[0];

            this.setState(
                {
                    filterRegion: defaultFilterRegion.toLowerCase().replace(/ /g, ''),
                    filterRegionTitle: defaultFilterRegion,
                },
                () => {
                    this.getFilteredContacts();
                }
            );
        });
    }

    componentDidMount() {
        this.fetchRegions();
        this.getDefaultRegion();
    }

    componentDidUpdate() {}

    onCheckboxChange = (e) => {
        if (!e.target) return;
        this.setState({
            filterFeaturedOnly: e.target.checked,
        });
    };

    //Param order set by plugin...
    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedMarkerId: props.id,
        });

        //////////////////////////////////
        // Interact with map with references
        //////////////////////////////////
        // const {google, map} = props;
        // const markerlatLng = new google.maps.LatLng(marker.lat, marker.lng);

        // map.panTo(markerlatLng);
        // map.setZoom(16);
        //window.google.maps.event.trigger(map, 'resize');
    };

    onInfoClose = () => {
        this.setState({
            selectedPlace: {},
            activeMarker: {},
            showingInfoWindow: false,
            selectedMarkerId: '',
        });
    };

    onContactClick = (e, contact) => {
        this.setState({ selectedMarkerId: contact.id });
    };

    getFilteredContacts = () => {
        userStateManager.getContactsByRegion(this.state.filterRegion, (data) => {
            this.setState({ contacts: data, isLoading: false });
        });
    };

    onRegionSelect = (e) => {
        this.setState(
            {
                filterRegion: e.target.value,
                filterRegionTitle: e.target.value,
                selectedMarkerId: '',
                contacts: [],
                isLoading: true,
            },
            () => {
                this.getFilteredContacts();
            }
        );
    };

    render() {
        //Only render contacts with valid lat and long
        if (this.state.filterFeaturedOnly) {
            var contactsToRender = this.state.contacts.filter(
                (item) => !isNullUndefinedEmpty(item.latitude) && !isNullUndefinedEmpty(item.longitude) && item.featured === 'True'
            );
        } else {
            var contactsToRender = this.state.contacts.filter(
                (item) => !isNullUndefinedEmpty(item.latitude) && !isNullUndefinedEmpty(item.longitude)
            );
        }
        return (
            <div className="contacts-locations">
                <ContactsFilter
                    onCheckboxChange={this.onCheckboxChange}
                    options={this.state.availableRegions}
                    onRegionSelect={this.onRegionSelect}
                    selectedOption={this.state.filterRegion}
                />
                <div className="contacts-locations__map-section">
                    <div className="contacts-locations__map">
                        {
                            <MapContainer
                                markers={contactsToRender}
                                onMarkerClick={this.onMarkerClick}
                                activeMarker={this.state.activeMarker}
                                showingInfoWindow={this.state.showingInfoWindow}
                                selectedPlace={this.state.selectedPlace}
                                selectedMarkerId={this.state.selectedMarkerId}
                                onInfoClose={this.onInfoClose}
                                mapRef={this.mapRef}
                            />
                        }
                    </div>
                    <ContactsList
                        contacts={contactsToRender}
                        onContactClick={this.onContactClick}
                        isLoading={this.state.isLoading}
                        filterFeaturedOnly={this.state.filterFeaturedOnly}
                    />
                </div>
            </div>
        );
    }
}
