import React from "react";
import ReactDOM from "react-dom";

class ReactRegisterComponent {
  constructor(reactComponent, componentTypeName) {
    var divs = document.querySelectorAll(
      `[data-react-type='${componentTypeName}']`
    );

    for (var component of divs) {
      // Gets props and renders component
      var props = JSON.parse(component.getAttribute("data-props"));
      ReactDOM.render(React.cloneElement(reactComponent, props), component);

      // Removes outer div by replacing with contents
      // This ensure all widgets are immediate siblings and hides props from DOM
      var reactContainer = component.children[0];

      if (reactContainer) {
        component.parentNode.replaceChild(
          reactContainer || component,
          component
        );
      }
    }
  }
}

export default ReactRegisterComponent;
