import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { isNullUndefinedEmpty } from "../../../helpers/helpers";

const Contact = ({ title, phone, onClick }) => {
  var regexNotWhiteSpace = /.*\S.*/;
  var phoneRender = "";

  if (!isNullUndefinedEmpty(phone) && regexNotWhiteSpace.test(phone)) {
    phoneRender = (
      <div className="phone">
        <i className="fa fa-phone" aria-hidden="true"></i>
        <span class="sr-only">phone</span>
        {phone}
      </div>
    );
  }

  return (
    <li>
      <div className="details">
        <div className="title">
          <strong>{title}</strong>
        </div>
        {phoneRender}
      </div>
      <button onClick={onClick}>
        <i className="fa fa-info-circle"></i>More info
      </button>
    </li>
  );
};

export class ContactsList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="contacts-locations__list">
        {this.props.isLoading ? (
          <div className="loader-wrapper loader-wrapper--centered">
            <div className="loader-2 loader-2--reverse">Loading...</div>
          </div>
        ) : (
          ""
        )}

        {this.props.contacts.length === 0 && !this.props.isLoading ? (
          <div className="no-contacts">
            It looks like there are no{" "}
            {this.props.filterFeaturedOnly ? "featured " : ""}contacts for the
            selected region.
          </div>
        ) : (
          ""
        )}

        {this.props.contacts.length > 0 && !this.props.isLoading ? (
          <Scrollbars
            style={{ height: 460 }}
            renderThumbVertical={(style, props) => (
              <div {...props} className="thumb-vertical" />
            )}
          >
            <ul>
              {this.props.contacts.map((contact, index) => (
                <Contact
                  key={contact.id}
                  id={contact.id}
                  title={contact.title}
                  phone={contact.phoneWork}
                  onClick={(e) => this.props.onContactClick(e, contact)}
                />
              ))}
            </ul>
          </Scrollbars>
        ) : (
          ""
        )}
      </div>
    );
  }
}
