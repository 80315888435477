import React from "react";

export class ContactsFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.$select = $(this.select);
    this.$checkbox = $(this.checkbox);

    //Refs set up, however JS is handling setting up the pseudo checkbox - this component does not re-render
  }

  render() {
    return (
      <div className="contacts-locations__filter">
        <div className="contacts-locations__select-wrap">
          <label htmlFor="contact-locations-region-filter">
            Select a region, or click and zoom in the map
          </label>
          <select
            id="contact-locations-region-filter"
            value={this.props.selectedOption
              .toLowerCase()
              .replace(/ /g, "")
              .replace(/\//g, "")}
            onChange={this.props.onRegionSelect}
            className="contacts-locations__select"
            ref={(select) => (this.select = select)}
          >
            {this.props.options.map((option, index) => (
              <option
                key={index}
                value={option
                  .toLowerCase()
                  .replace(/ /g, "")
                  .replace(/\//g, "")}
              >
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}
