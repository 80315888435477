import { InfoWindow, Marker } from "google-maps-react";

import React from "react";

export class MarkersList extends React.Component {
  constructor(props) {
    super(props);
    this.markersRendered = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      JSON.stringify(this.props.markers) ===
        JSON.stringify(nextProps.markers) &&
      this.markersRendered
    ) {
      return false;
    }
    this.markersRendered = true;
    return true;
  }

  render() {
    const markerIcon = {
      url: "/assets/static/icons/icon-map-pin.png",
      scaledSize: { width: 24, height: 40 },
    };

    return (
      <React.Fragment>
        {this.props.markers.map((marker, index) => {
          return (
            <Marker
              {...this.props}
              key={marker.id}
              id={marker.id}
              position={{ lat: marker.latitude, lng: marker.longitude }}
              icon={markerIcon}
              onClick={this.props.onMarkerClick}
              title={marker.title}
              description={marker.title}
              lat={marker.latitude} //For passing to info window
              lng={marker.longitude} //For passing to info window
            ></Marker>
          );
        })}
      </React.Fragment>
    );
  }
}
