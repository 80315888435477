import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react"; //https://www.npmjs.com/package/google-maps-react

import { MarkersList } from "./MarkersList";
import React from "react";
import { isNullUndefinedEmpty } from "../../../helpers/helpers";

export class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.map = {};
  }

  onMapReady = (mapProps, map) => {
    //Set up map events here
    //This method only fires once
    //In theory we could set up markers here (might need to for clusters)

    this.map = map; //Reference the map in other custom methods with this

    const { google, markers } = mapProps;
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.selectedMarkerId == prevProps.selectedMarkerId ||
      (isNullUndefinedEmpty(this.props.selectedMarkerId) &&
        isNullUndefinedEmpty(prevProps.selectedMarkerId))
    ) {
      if (this.props.markers.length > 0) {
        var _bounds = new google.maps.LatLngBounds();
        this.props.markers.forEach((marker, index) => {
          if (!isNullUndefinedEmpty(marker.latitude)) {
            _bounds.extend({
              lat: Number(marker.latitude),
              lng: Number(marker.longitude),
            });
          }
        });
        this.map.fitBounds(_bounds, 100);
      }
    } else if (
      this.props.selectedMarkerId !== prevProps.selectedMarkerId &&
      !isNullUndefinedEmpty(this.props.selectedMarkerId)
    ) {
      //Zoom and pan to selected marker
      let activeMarker = this.getMarkerFromId(this.props.selectedMarkerId);
      if (
        !isNullUndefinedEmpty(activeMarker.latitude) &&
        !isNullUndefinedEmpty(activeMarker.longitude)
      ) {
        const markerlatLng = new google.maps.LatLng(
          activeMarker.latitude,
          activeMarker.longitude
        );

        // this.map.panTo(markerlatLng);

        var currentZoom = this.map.getZoom();
        if (currentZoom < 8) {
          this.map.setZoom(8);
        }
      }
    }
  };

  getMarkerFromId = (id) => {
    return this.props.markers.filter((marker) => marker.id === id)[0];
  };

  getInfoWindowMarkup = (infoWindowMarker) => {
    return (
      <div className="info-window">
        <div className="title">{infoWindowMarker.title}</div>
        {!isNullUndefinedEmpty(infoWindowMarker.description) ? (
          <p>{infoWindowMarker.description}</p>
        ) : (
          ""
        )}
        <table className="dfes-o-table dfes-o-table--small">
          <tbody>
            <tr>
              <th scope="row">Phone (work)</th>
              <td>
                {!isNullUndefinedEmpty(infoWindowMarker.phoneWork)
                  ? infoWindowMarker.phoneWork
                  : "-"}
              </td>
            </tr>
            <tr>
              <th scope="row">Phone (mobile)</th>
              <td>
                {!isNullUndefinedEmpty(infoWindowMarker.mobile)
                  ? infoWindowMarker.mobile
                  : "-"}
              </td>
            </tr>
            <tr>
              <th scope="row">Fax</th>
              <td>
                {!isNullUndefinedEmpty(infoWindowMarker.fax)
                  ? infoWindowMarker.fax
                  : "-"}
              </td>
            </tr>
            <tr>
              <th scope="row">Email</th>
              <td>
                {!isNullUndefinedEmpty(infoWindowMarker.email) ? (
                  <a href={"mailto:" + infoWindowMarker.email}>
                    {infoWindowMarker.email}
                  </a>
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">Address</th>
              <td>
                {!isNullUndefinedEmpty(infoWindowMarker.street)
                  ? infoWindowMarker.street + " " + infoWindowMarker.city
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
        {!isNullUndefinedEmpty(infoWindowMarker.web) ? (
          <a href={infoWindowMarker.web} target="_blank">
            More on {infoWindowMarker.title}
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };

  render() {
    //Can pass a marker reference to info window via plugin's click event,
    //but we need to trigger from the list as well... so use an id
    var infoWindowMarker = {};
    var infoWindowVisible = false;
    var infoWindowLat = 0;
    var infoWindowLng = 0;

    var initialCenter = { lat: -31.949, lng: 115.858 }; //Todo - based on markers

    //Get lat/lng from a id to filter
    if (this.props.selectedMarkerId.length > 0) {
      let activeMarker = this.getMarkerFromId(this.props.selectedMarkerId);
      infoWindowLat = Number(activeMarker.latitude);
      infoWindowLng = Number(activeMarker.longitude);

      if (infoWindowLat && infoWindowLng) {
        infoWindowVisible = true;
        infoWindowMarker = activeMarker;
        initialCenter = { lat: infoWindowLat, lng: infoWindowLng };
      }
    }

    return (
      <Map
        google={this.props.google}
        zoom={12}
        initialCenter={initialCenter}
        disableDefaultUI={true}
        onReady={(mapProps, map) => this.onMapReady(mapProps, map)}
      >
        <MarkersList
          markers={this.props.markers}
          onMarkerClick={this.props.onMarkerClick}
        />

        <InfoWindow
          visible={infoWindowVisible}
          position={{ lat: infoWindowLat, lng: infoWindowLng }}
          options={{ pixelOffset: { width: 0, height: -51 } }}
          onClose={this.props.onInfoClose}
        >
          {this.getInfoWindowMarkup(infoWindowMarker)}
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDELI8qLOVLDKfM6XggxIgtxKL06Q_pzzA",
})(MapContainer);
